<template>
  <div class="shop">
    <div class="myShop">
      <p class="myShopTitle">{{ $t.meta.myStore }}</p>
      <div class="myShopRight">
        <button class="button-left" @click="goMyStore">
          {{ $t.meta.enterMyStore }}
        </button>
        <button class="button-right" @click="shareMyStore">
          {{ $t.meta.shareMyStore }}
        </button>
      </div>
    </div>

    <ul class="items">
      <li class="itemBox" @click="goEarning">
        <figure class="itemImg">
          <img
            class="itemImgIcon"
            src="~assets/img/store/icon_my_store_profit@2x.png"
          />
        </figure>
        <p class="itemTitle">{{ $t.earnings }}</p>
      </li>
      <li class="itemBox" @click="goCommodity">
        <figure class="itemImg">
          <img
            class="itemImgIcon"
            src="~assets/img/store/icon_my_store_commodity@2x.png"
          />
        </figure>
        <p class="itemTitle">{{ $t.commodity }}</p>
      </li>
      <li class="itemBox" @click="goIndent">
        <figure class="itemImg">
          <img
            class="itemImgIcon"
            src="~assets/img/store/icon_my_store_order@2x.png"
          />
        </figure>
        <p class="itemTitle">{{ $t.buyerOrder }}</p>
      </li>
      <li class="itemBox" @click="goAnalyze">
        <figure class="itemImg">
          <img
            class="itemImgIcon"
            src="~assets/img/store/icon_my_store_analysis@2x.png"
          />
        </figure>
        <p class="itemTitle">{{ $t.operatingData }}</p>
      </li>
      <li class="itemBox" @click="goCoupon">
        <van-badge
          color="#ff5852"
          :content="shopData.ShopCouponCount ? shopData.ShopCouponCount : null"
        >
          <figure class="itemImg">
            <img class="itemImgIcon" src="~assets/img/store/coupon_icon.png" />
          </figure>
          <p class="itemTitle">{{ $t.coupon }}</p>
        </van-badge>
      </li>
      <li class="itemBox" @click="goFans">
        <figure class="itemImg">
          <img class="itemImgIcon" src="~assets/img/store/fans_icon.png" />
        </figure>
        <p class="itemTitle">{{ $t.fans }}</p>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "myShop",
  props: {
    userData: {
      type: Object,
    },
    shopData: {
      type: Object,
    },
  },
  data() {
    return {
      shareShopData: {},
    };
  },
  methods: {
    goCoupon() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "coupon",
        name: "coupon",
        query: [],
      });
    },
    goFans() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "fans",
        name: "fans",
        query: [],
      });
    },
    goEarning() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "earnings",
        name: "earnings",
        query: [],
      });
    },
    goCommodity() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "commodityManage",
        name: "commodityManage",
        query: [],
      });
    },
    goIndent() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "indent",
        name: "indent",
        query: [],
      });
    },
    goAnalyze() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "analyze",
        name: "analyze",
        query: [],
      });
    },
    goMyStore() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "mystore",
        name: "mystore",
        query: [
          {
            name: "ShopId",
            value: this.userData.ShopId,
          },
        ],
      });
    },
    goEditStore() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "editStore",
        name: "EditStore",
        query: [],
      });
    },
    shareMyStore() {
      if (this.userData) {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
        let param = {};
        this.$api.shop
          .loadShopInfo(param)
          .then((res) => {
            this.shareShopData = res.data;
            this.$commonEnv.commonAction(
              this.$commonEnv.actionType.hideLoading
            );
            if (
              this.shareShopData.ShopHeadPhoto ==
              "/UploadFiles/Users/icon/icon_mine_portrait.png"
            ) {
              this.$commonMethod.customAlert(
                {
                  message: this.$t.alert.shopText,
                  cancelButtonText: this.$t.alert.cancelButtonText,
                  confirmButtonText: this.$t.alert.goUpload,
                },
                () => {
                  this.goEditStore();
                },
                () => {}
              );
            } else {
              this.$commonEnv.commonAction(
                this.$commonEnv.actionType.shareShop,
                {
                  name: this.shareShopData.ShopName,
                  image: this.shareShopData.ShopHeadPhoto,
                  linkurl: this.shareShopData.ShopUrl,
                }
              );
            }
          })
          .catch((error) => {
            this.$commonEnv.commonAction(
              this.$commonEnv.actionType.hideLoading
            );
            this.$commonEnv.commonAction(
              this.$commonEnv.actionType.showDialog,
              {
                msg: error.message,
                status: error.status,
              }
            );
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.shop {
  width: 95%;
  margin: 0 auto;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  .myShop {
    @include publicFlex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    padding: 8px 10px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    .myShopTitle {
      @include divPublic;
      color: #2ea897;
      font-weight: bold;
    }
    .myShopRight {
      max-width: 75%;
      @include publicFlex;
      .button-left {
        background: transparent;
        color: #2ea897;
        border: 1px solid #2ea897;
        padding: 2px 7px 2px 7px !important;
        border-radius: 50px;
        font-size: 14px;
      }
      .button-right {
        background: #2ea897;
        color: #ffffff;
        border: 1px solid transparent;
        padding: 2px 7px 2px 7px !important;
        border-radius: 50px;
        margin-left: 5px;
        font-size: 14px;
      }
    }
  }
  .items {
    width: 100%;
    padding: 0;
    margin: 0 auto;
    justify-content: space-around;
    -webkit-justify-content: space-around;
    list-style: none;
    padding-bottom: 15px;
    .itemBox {
      padding-top: 10px;
      text-align: center;
      width: 25%;
      list-style: none;
      display: inline-block;
      .itemImg {
        width: 45px;
        height: 45px;
        display: inline-block;
        margin: 0;
        .itemImgIcon {
          width: 100%;
        }
      }
      .itemTitle {
        @include divPublic;
        color: #666;
        font-size: 14px;
      }
      ::v-deep .van-badge {
        top: 5px !important;
        right: 5px !important;
        border: none !important;
        padding: 1px 1px 1px 1px;
        font-family: initial !important;
      }
    }
  }
}
</style>
